.img-rotate {
    animation: imgRotate 50s linear infinite;
  }
  @keyframes imgRotate { 
    100% { 
        transform: rotate(360deg); 
    } 
}
:root {
    --primary: #FEA116;
    --light: #F1F8FF;
    --dark: #0F172B;
  }
  .ff-secondary {
    font-family: 'Pacifico', cursive;
  }
/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  padding: 35px 0;
  text-transform: uppercase;
  font-weight: 600;
  outline: none;
  transition: .5s;
}
.navbar-dark .navbar-collapse .navbar-nav .nav-link:hover,
.navbar-dark .navbar-collapse .navbar-nav .nav-link.active {
    color: var(--primary) !important;
}
nav div div a#login-nav,
nav div div a#register-nav {
  color: var(--dark) !important;
}
/*** Button ***/
.btn {
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    transition: .5s;
}
.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}
.btn-square {
    width: 38px;
    height: 38px;
}
.btn-sm-square {
    width: 32px;
    height: 32px;
}
.btn-lg-square {
    width: 48px;
    height: 48px;
}
.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 2px;
}
.loaded {
  opacity: 0.9;
}
/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}
.service-item:hover {
  background: var(--primary);
}
.service-item * {
  transition: .5s;
}
.service-item:hover * {
  color: var(--light) !important;
}
nav div div a.selected1 {
  margin-inline: 15px !important;
}
nav div div a.selected {
  margin-inline: 6px !important;
}